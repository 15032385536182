html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit !important;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.disable_Card {
  position: relative;
}
.disable_Card::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: grayscale(100%);
}

.socialMediaButton {
  border: none;
  margin-right: 30px;
}

.socialMediaButton:focus {
  outline: none;
}

.socialMediaButton:hover > svg {
  height: 50px !important;
  width: 50px !important;
}
.my-facebook-button-class{
  border: none;
}
.ck-editor__editable > ul{
  list-style: inside;
}
.parse_text_offer_info > ul{
  list-style: inside;
}

.cardTopImage {
  margin-top: 10px;
  cursor: pointer;
}
.cardOfferImage {
  cursor: pointer;
}
@media (max-width: 970px) {
  .cardTopImage {
  display: none;
  }
}

.dropdown-content{
  background-color: var(--white);
  box-shadow: 0 5px 10px rgba(0,0,0,0.3);
}

.marginAnimation {
  margin-left: 70px !important;
  transition: all .3s;
  @media (max-width: 768px) {
    margin-left: 0px !important;
  }
}

.multiselects .dropdown-container {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  background-color: white;
}

.multiselects .dropdown-content {
  background-color: white;
  padding: 4px;
}

.swal2-container {
  z-index: 2000 !important;
}

.swal2-popup.swal2-toast .swal2-title {
  font-size: 1.5em !important;
}

.swal2-popup.swal2-toast .swal2-html-container, .swal2-html-container {
  font-size: 1.3em !important;
}

.ql-editor {
  min-height: 100px;
  font-size: 1.3em;
  background-color: white;
}

.custom-dialog-content {
  &::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: var(--morado4);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--morado2);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track:hover,
  &::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }
}
